<template>
    <div class="search_status_box flex">
        <div class="search_box flex">
            <div class="order_search flex">
                <img src="@/assets/user/setMenu/search.png" alt="" class="search_icon" />
                <el-input @change="search" class="order_search_inp" v-model="title" :placeholder="place_holder"></el-input>
                <button class="search_but" @click="search">搜索</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: '',
    props:{
        place_holder:{
            type:String,
            default:'输入媒介名称'
        }
    },
    data() {
        return {
            title: '',

        }
    },
    methods: {
        search() {
            this.$emit('search', this.title)
        }
    },
}
</script>

<style lang=scss scoped>
.search_status_box {
    justify-content: space-between;
    align-items: center;

    .time_search {
        width: 17rem;
        height: 3.33rem;
        font-size: 1rem;
    }

    .order_search {
        align-items: center;
        border: 0.08rem solid #c5e6fc;
        border-radius: 0.42rem;
        width: 18.33rem;
        height: 30px;
        padding: 0 0 0 1rem;
        box-sizing: border-box;
        justify-content: space-between;
        margin-right: 1.5rem;

        .search_icon {
            width: 15px;
        }
        ::v-deep .el-input__inner {
            border: 0;
            height: 28px;
            padding-right: 0;
            outline: none;
            color: #6f90a6;
            font-size: 1.3rem;
            padding-right: 1rem;
            font-size: 14px;
        }

        .search_but {
            cursor: pointer;
            width: 6.83rem;
            height: 30px;
            background: #b5e1ff;
            color: #fff;
            border: 0;
            border-radius: 0rem 0.42rem 0.42rem 0rem;
        }
    }
}
</style>